body .p-paginator {
  background-color: #fff;
  border: 0;
  padding: 0;

  .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em;
    padding: 0;
    margin: 0;
    vertical-align: top;
    border-radius: 0;
    outline: none;
  }

  .p-paginator-first:not(.p-state-disabled):not(.p-highlight):hover,
  .p-paginator-prev:not(.p-state-disabled):not(.p-highlight):hover,
  .p-paginator-next:not(.p-state-disabled):not(.p-highlight):hover,
  .p-paginator-last:not(.p-state-disabled):not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: #333333;
  }

  .p-dropdown {
    border: 0 none;

    .p-dropdown-trigger,
    .p-dropdown-label {
      color: #848484;
    }

    &:hover {
      .p-dropdown-trigger,
      .p-dropdown-label {
        color: #333333;
      }
    }
  }

  .p-paginator-first:before {
    position: relative;
    top: 1px;
  }

  .p-paginator-prev:before {
    position: relative;
  }

  .p-paginator-next:before, .p-paginator-last:before {
    position: relative;
    top: 1px;
  }

  .p-paginator-pages {
    vertical-align: top;
    display: inline-block;
    padding: 0;

    .p-paginator-page {
      color: #848484;
      height: 2.286em;
      min-width: 2.286em;
      border: 0 none;
      line-height: 2.286em;
      padding: 0;
      margin: 0;
      vertical-align: top;
      border-radius: 0;
      outline: none;

      &.p-highlight {
        background-color: $brand-primary;
        color: #ffffff;
      }

      &:not(.p-highlight):hover {
        background-color: #e0e0e0;
        color: #333333;
      }
    }
  }

  .p-dropdown {
    margin-left: 0.5em;
    height: 2.286em;
    min-width: auto;
  }
}
