/**
 * Pace
 */
.pace .pace-progress {
  background: $brand-primary;
  position: absolute;
  top: $navbar-height;
}

.pace .pace-activity {
  position: absolute;
  top: 65px;
  border-width: 1px;
  left: 15px;
  right: auto;
  width: 18px;
  height: 18px;
  border-top-color: $brand-primary;
  border-left-color: $brand-primary;
}

.pace .pace-progress-inner {
  right: -10px;
  width: 10px;
  box-shadow: none;
}

/**
 * PrimeNG
 */

.p-dropdown {
  .p-dropdown-trigger {
    width: 28px;

    .fa {
      margin-top: 8px;
      margin-left: 1px;
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-filter-container {
    display: block;
  }
}

.btn-relative {
  position: relative;
}

.kstbutton {
  position: absolute;
  width: 40px !important;
  height: 44px;
  top: 0;
  right: 15px;

  label {
    display: none !important;
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  @include transition(all .2s ease-in-out);

  &.p-dropdown {
    .p-dropdown-trigger {
      width: 40px;

      .fa {
        margin-top: 16px;
        color: #fff;
      }

      &.p-state-default {
        border: none;
        text-shadow: none;
        background-color: $brand-primary;
        border-radius: 0 0.3em 0.3em 0;
        background-image: none;
        color: $white;
        padding: 0 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        @include transition(all .2s ease-in-out);
      }

      &:focus {
        outline: 0;
        box-shadow: none !important;
      }
    }
  }

  &.p-state-default {
    border: none;
    text-shadow: none;
    background-color: $brand-primary;
    border-radius: 0 0.3em 0.3em 0;
  }

  .p-inputtext {
    font-size: 1.25rem;
    border-radius: 0.3rem 0 0 0.3rem;
    color: $white;
    background-color: $brand-primary;
    padding: 0.75rem 1.5rem !important;
    box-shadow: none;
    text-align: center;
    @include transition(all .2s ease-in-out);

  }

  &:hover {
    .p-inputtext {
      background-color: darken($brand-primary, 10%)
    }

    &.p-dropdown {
      .p-dropdown-trigger {
        &.p-state-default {
          background-color: darken($brand-primary, 10%);
        }
      }
    }
  }
}


.p-state-focus {
  outline: 0 !important;
}

mfDefaultSorter > a {
  color: $gray;

  &:hover {
    color: $gray;
    text-decoration: none;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  @include border-radius();

  > li {
    display: inline; // Remove list-style and block-level defaults

    &:first-child {
      a {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      a {
        @include border-right-radius($border-radius);
      }
    }

    &.active a {
      @include plain-hover-focus {
        z-index: 2;
        color: $pagination-active-color;
        cursor: default;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border;
      }
    }

    &.disabled a {
      @include plain-hover-focus {
        color: $pagination-disabled-color;
        cursor: $cursor-disabled;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
      }
    }
  }

  > li > a {
    position: relative;
    float: left; // Collapse white-space
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -1px;
    //line-height: $line-height;
    color: $pagination-color;
    text-decoration: none;
    background-color: $pagination-bg;

    @include hover-focus {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }
}

/***********************************/
/**           Backgrid            **/
/***********************************/

.table-editable {

  th > a {
    color: $text-color;
    cursor: pointer;
  }

  .sort-caret {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 0.3em;
    border: 0;
    content: "";
    font: 15px FontAwesome;
  }

  .ascending .sort-caret {
    &:before {
      content: "\f0d8";
    }
  }

  .descending .sort-caret {
    &:before {
      content: "\f0d7";
    }
  }

  + .backgrid-paginator > ul {
    font-weight: $font-weight-normal;
    display: inline-block;
    padding-left: 0;
    margin: $line-height-computed 0;
    border-radius: $border-radius;

    > li {
      display: inline; // Remove list-style and block-level defaults
      > a,
      > span {
        position: relative;
        float: left; // Collapse white-space
        line-height: $line-height-base;
        text-decoration: none;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: 1px solid $pagination-border;
        border-radius: $border-radius;
        margin: 0 2px;

        > .fa-lg {
          position: relative;
          top: -1px;
        }
      }

      &:first-child {
        > a,
        > span {
          margin-left: 0;
        }
      }
    }

    > li > a,
    > li > span {
      &:hover,
      &:focus {
        color: $pagination-hover-color;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border;
      }
    }

    > .active > a,
    > .active > span {
      &,
      &:hover,
      &:focus {
        z-index: 2;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border;
        cursor: default;
      }
    }

    > .disabled {
      > span,
      > span:hover,
      > span:focus,
      > a,
      > a:hover,
      > a:focus {
        color: $pagination-disabled-color;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
        cursor: not-allowed;
      }
    }

    > li > a {
      padding: $padding-small-vertical $padding-small-horizontal;
      font-size: $font-size-sm;
      line-height: $line-height-computed;
    }

    > li {
      &:first-child {
        > a {
          @include border-left-radius($border-radius-sm);
        }
      }

      &:last-child {
        > a {
          @include border-right-radius($border-radius-sm);
        }
      }
    }
  }
}

.fullwidthinput {
  display: block;
  width: 100%;

  input {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    label {
      display: block;
    }
  }
}

.calendar-data {
  th {
    text-align: center;
  }
}

.weekend {
  background-color: #eee;
}

.red {
  color: red;
}

.green {
  color: green;
}

.fullwidthDiv {
  display: block;
  width: 100% !important;
}

/***********************************/
/**           Accordion           **/
/***********************************/


body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-state-highlight {
  background-color: $brand-primary;
  color: #ffffff;
}

body {
  .p-widget, .p-component {
    font-family: inherit;
    .p-widget, input, select, textarea, button {
      font-family: inherit;
    }
  }
}

body .p-button {
  //background-color: $brand-primary!important;
  border: 0 !important;
}

body .p-splitbutton .p-button {
  background-color: $brand-primary!important;
}

body .p-button:enabled:hover, body .p-button:not(.no-focus):focus {
  background-color: lighten($brand-primary, 10%);
}


.p-grid .p-grid-row {
  padding: 4px 10px;
}


.geodatatoggle, .onlinestatetoggle {
  padding-left: 1rem;
  margin-bottom: 10px;
}

.geodatatoggle span {
  display: block;
}

.onlinestatetoggle {
  .p-button.p-togglebutton {
    &.p-highlight {
      background-color: $brand-success;

      &:hover {
        background-color: darken($brand-success, 10%);
      }
    }

    background-color: $brand-danger;
    color: white;

    &:not(.p-state-disabled):not(.p-highlight):hover {
      background-color: darken($brand-danger, 10%);
    }

    &.p-state-disabled {
      opacity: 1;
    }
  }
}

body .p-inputswitch {
  background-color: $brand-primary;
}

body .p-togglebutton {
  background-color: #eee;
  color: black;

  &:not(.p-state-disabled).p-highlight {
    background-color: $brand-primary!important;
    color: white!important;
  }

  &.p-highlight:hover {
    background-color: $brand-primary-light!important;
  }

  &:not(.p-state-disabled):not(.p-highlight):hover {
    background-color: #eee;
  }
}

body .p-highlight, body .p-state-highlight {
  background-color: $brand-primary;
  color: #ffffff;
}

body .p-paginator .p-paginator-pages .p-paginator-page:hover.p-highlight {
  background-color: lighten($brand-primary, 10%);
}


.btn-lg-split.p-splitbutton {
  width: 100%;
  border-radius: 0.3rem;
  overflow: hidden;

  .p-button {
    width: 80%;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0.79rem 1.5rem !important;

    &.p-splitbutton-menubutton {
      width: 20%;
    }
  }
}

.p-datatable .p-datatable-header {
  border-width: 0px!important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: none!important;
}

.p-menu-separator {
  height: 1px;
  background-color: #eee;
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}
