@use "@material/tooltip";
@use "@material/tooltip/styles";

// Start of theme
@import "variables";
@import "mixins";
@import "fonts";

// External libraries

//@import "~bootstrap/scss/bootstrap";
/*!
 * Bootstrap v4.0.0-alpha.4 (http://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "~bootstrap/scss/custom";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Reset and dependencies
@import "~bootstrap/scss/normalize";
@import "~bootstrap/scss/print";

// Core CSS
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

// Components
@import "~bootstrap/scss/animation";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/tags";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/responsive-embed";
@import "~bootstrap/scss/close";

// Components w/ JavaScript
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";

// Utility classes
@import "~bootstrap/scss/utilities";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/nova/theme.css";
@import "components/nove-theme";
@import "~primeng/resources/primeng.min.css";
@import "~messenger-hubspot/build/css/messenger-theme-flat.css";
@import "~messenger-hubspot/build/css/messenger.css";
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';

// Theme styling
@import "font";
@import "bootstrap-override";
@import "libs-override";
@import "general";
@import "base";
@import "utils";
@import "print";
@import "spinner";
@import "login";
@import "error";
@import "infocenter";
@import "calendar";
@import "material-override";

@import "components/table";
@import "components/paginator";
@import "components/accordion";
@import "components/inputtext";
@import "components/dropdown";
@import "components/button";
@import "components/datepicker";
@import "components/button";
@import "components/scheduler";
@import "components/image-upload";
@import "components/clockpicker";
@import "components/code-scanner";
@import "components/active-bde";
@import "components/flip-card";
@import "components/card";

@import 'primeflex';
