body .p-accordion {

  .p-accordion-header {
    color: $brand-primary;

    a {
      padding: 0.571em 1em;
      //border: 1px solid #c8c8c8;
      color: #333333;
      background-color: #f4f4f4;
      font-weight: 400;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;

      .p-accordion-toggle-icon {
        color: #848484;

        &.pi-caret-right:before {
          content: "";
        }

        &.pi-caret-down:before {
          content: "";
        }
      }
    }

    &:not(.p-highlight):not(.p-state-disabled):hover a {
      background-color: #dbdbdb;
      border: 1px solid #dbdbdb;
      color: #333333;

      .p-accordion-toggle-icon {
        color: #333333;
      }
    }

    &:not(.p-state-disabled).p-highlight {
      a {
        background-color: #fff;
        border: 0;
        color: #ffffff;

        .p-accordion-toggle-icon {
          color: #ffffff;
        }
      }

      &:hover a {
        border: 0;
        background-color: #fff;
        color: #ffffff;

        .p-accordion-toggle-icon {
          color: #ffffff;
        }
      }
    }
  }

  .p-accordion-content {
    padding: 0;
    background-color: #ffffff;
    color: #333333;
  }

  margin-bottom: 20px;

  .p-accordion-header {
    background: #fff;
    border: 0;
    margin-bottom: 0;
    border-radius: 3px;

    a {
      color: #555 !important;
      font-size: 1.2rem;
      text-transform: uppercase;
      text-decoration: none;
      padding: 8px 20px;
    }

    &.p-highlight {
      margin-bottom: 0;
      border-radius: 3px 3px 0 0;
    }
  }

  .p-accordion-content {
    &.p-widget-content {
      padding: 0;
      border: 0;
      border-radius: 0 0 3px 3px;
    }
  }

  .p-accordion-header .fa {
    position: absolute;
    right: .5em;
    left: auto;
    top: 50%;
    margin-top: -8px;

    &.fa-caret-right:before {
      content: "\f055";
    }

    &.fa-caret-down:before {
      content: "\f056";
    }
  }
}


body .p-accordion {

}

body .p-accordion .p-accordion-header a,
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-highlight a,
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-highlight:hover a,
body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-state-disabled):hover a {
  background-color: #fff;
  border: 0;
  color: #ffffff;
  outline: 0 !important;
}

body .p-accordion .p-accordion-header:not(.p-state-disabled).p-highlight a .p-accordion-toggle-icon,
body .p-accordion .p-accordion-header:not(.p-state-disabled).p-highlight:hover a .p-accordion-toggle-icon,
body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-state-disabled):hover a .p-accordion-toggle-icon {
  color: #555;
}

body .p-accordion .p-accordion-header {
  color: $brand-primary;
}

body .p-accordion .p-accordion-header.p-highlight {
  background-color: #fff;
  color: #555;
}
