/*Infocenter styles - hier definiert da die ampel auch im menü auftaucht.  */
.text-infocenter-ampel {
  font-size: 1.3em !important;
}


/* 0=kein auftrag aktiv */
.text-Anwesend, .text-Anwesend-0 {
  @extend .text-infocenter-ampel;
  color: green!important;
}

.text-Abwesend, .text-Abwesend-0 {
  @extend .text-infocenter-ampel;
  color: red!important;
}

.text-Pause, .text-Pause-0 {
  @extend .text-infocenter-ampel;
  color: yellow!important;
}

.text-Dienstgang, .text-Dienstgang-0 {
  @extend .text-infocenter-ampel;
  color: orange;
}


/* 1=auftrag aktiv */

.text-Anwesend-1,
.text-Abwesend-1,
.text-Pause-1,
.text-Dienstgang-1 {
  @extend .text-infocenter-ampel;
  color: blue;
}

.blocklayout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  z-index: 10000000;
}

.version {
  bottom: 0;
  font-size: 12px;
  padding-left: 1rem;
  color: #bbb;
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}

.logout {
  bottom: 100px;
  font-size: 14px;
  display: block;
  width: 100%;
  @include media-breakpoint-up(md) {
    position: absolute;
  }

  .offline-jobs {
    padding-left: 1rem;
  }

  a {
    position: relative;
    border-top: 1px solid #2d2d2d;
    padding-left: 1rem;
    line-height: 35px;
    display: block;
    color: #fff;
    text-decoration: none;

    &:hover {
      background-color: #111;
    }
  }
}

.tx-app-info {
  &.p-dialog {
    .p-dialog-titlebar {
      font-weight: bold;
    }

    .p-dialog-content {
      table {
        tr:nth-child(even) {
          background: #eee;
        }

        td {
          padding: 8px;
          vertical-align: top;

          &:first-child {
            font-weight: bold;
          }

          &:last-child {
            max-width: 220px;
            font-style: italic;
          }
        }
      }
    }
  }
}
