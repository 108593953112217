/**
* if set to device-width ie10 built-in scrollbar overflows content. Fix it as of here
* http://stackoverflow.com/questions/17045132/scrollbar-overlay-in-ie10-how-do-you-stop-that-might-be-bootstrap
*/
@-ms-viewport {
  width: auto;
}

html, body {
  //min-height: 100vh;
}

body {
  font-weight: $font-weight-base!important;
  overflow-x: hidden;
}

.p-component {
  font-weight: $font-weight-base;
}

th {
  font-weight: $font-weight-semi-bold;
}

a {
  cursor: pointer;
}

svg {
  width: 100%;
}

strong, b {
  font-weight: bold;
}

abbr[title] {
  text-decoration: none;
}
