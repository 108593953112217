.fc-day-grid-event .fc-time {
  font-weight: normal;
}

.fc-event {
  min-height: 25px;

  &.pze-event {
    // max-width: 60%;
  }
}

.fc-day-grid .p-widget-content .p-state-highlight {
  background-image: none;
  background-color: #d7d7d7;
  text-shadow: none;
}

body .fc-time-grid .p-state-highlight {
  background: transparent;
}

.fc-toolbar .fc-right {
  @include media-breakpoint-down(sm) {
    float: none;
    display: block;
    height: 40px;
  }
}

.fc-toolbar .fc-left {
  @include media-breakpoint-down(sm) {
    float: none;
    display: block;
    height: 40px;
  }
}

.fc-time-grid-event .fc-description {
  padding: 0 1px;
}

.scheduler__backlink {
  background: $brand-primary;
  color: white;
  border-radius: 3px;
  height: 40px;
  width: 100px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 6px;
  cursor: pointer;
}

body .fc .fc-toolbar .fc-button {
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
}

body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: rgba($brand-primary, 0.7);
  border-color: rgba($brand-primary, 0.7);
}

body .fc-unthemed td.fc-today,
body .fc-bootstrap td.fc-today {
  background: transparent;
}

body .fc th.fc-day-header.fc-today,
body .fc-unthemed .fc-view.fc-dayGridMonth-view td.fc-widget-content.fc-today,
body .fc-bootstrap .fc-view.fc-dayGridMonth-view td.fc-today {
  box-shadow: inset 1px 1px 0 $brand-primary, inset -1px -1px $brand-primary;
}

body .fc-button-primary:not(:disabled):active:focus,
body .fc-button-primary:not(:disabled).fc-button-active:focus,
body .fc-bootstrap .fc-view.fc-dayGridMonth-view td.fc-day-top.fc-today {
  box-shadow: none;
}

body .fc-mySettingsButton-button {
  position: relative;
  outline: 0 !important;
}

body .dropdown-menu.fc-settings {
  left: auto;
  right: 15px;
  outline: 0 !important;
}


body .txBookProtocol.p-accordion {
  .p-accordion-header a {
    padding: 0;
  }
  .p-accordion-toggle-icon {
    text-align: left;
    font-size: 14px;
  }
  .p-accordion-header-text {
    font-size: 14px;
    text-transform: none;
    color: #333;
  }
}

body .fc .fc-timegrid-event-harness {
  max-width: 70%;
}

body .fc .fc-timegrid-event-harness .fc-event,
body .fc-bootstrap .fc-timegrid-event-harness .fc-event,
body .fc-bootstrap .fc-timegrid-event-harness a.fc-event:not([href]):not([tabindex]) {
  color: #555!important;
  font-weight: 400!important;
}

.fc-timegrid-bg-harness .fc-event-title {
  color: white !important;
  text-align: right;
  text-shadow: 0px 0px 5px #000000, 0px 0px 5px #000000, 0px 0px 5px #000000, 0px 0px 5px #000000;
}

.fc-daygrid-event-harness {
  overflow: hidden;
}

.fc-bg-event .fc-event-title {
  color: white !important;
  text-shadow: 0px 0px 5px #000000, 0px 0px 5px #000000, 0px 0px 5px #000000, 0px 0px 5px #000000;
}

.fc-event-title-container {
  overflow: hidden;
}

.event-absence {
  border-radius: 0!important;
}

.event-summary {
  text-align: center;
  border-radius: 0!important;
  background-color: rgba(180,180,180,0.2)!important;
}
