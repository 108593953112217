.image-upload-panel {
  text-align: center;

  img {
    max-width: 100%;
  }

  &.p-panel .p-panel-titlebar {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }


  .p-panel-content {
    display: none;
  }

  &--ready {
    .p-panel-content {
      display: block;
    }

    &.p-panel .p-panel-titlebar {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

}

.driver-license-check {
  .p-dialog-titlebar-icon {
    font-size: 24px;
  }
}
