.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  .flip-card__inner {
    position: relative;
    
    transition: transform 0.8s;
    transform-style: preserve-3d;

    &.flip {
      transform: rotateY(180deg);

      .login-form input[type=checkbox] {
        display: none;
      }
    }
  }

  /* Position the front and back side */
  .flip-card__front, .flip-card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }


  /* Style the back side */
  .flip-card__back {
    transform: rotateY(180deg);
  }
}
