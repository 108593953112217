body .tx-warning-card {

  &:hover {
    .pre-header i {
      transform: scale(2) rotate(10deg);
    }
  }

  .pre-header {
    background: $brand-danger;
    text-align: center;
    padding: 20px;
    margin-top: -15px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 15px;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    overflow: hidden;

    i {
      transform: scale(1) rotate(0deg);
      transition: transform 0.3s ease;
      font-size: 100px;
      color: white;
    }
  }
}
