.active-bde {
  .title {
    margin-top: 0;

    h4 {
      color: #555 !important;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 400;
      padding: 0;
    }

    h5 {
      color: #555 !important;
      text-transform: uppercase;
      padding: 0;
    }

    .timedata {
      text-align: right;
    }
  }

  dl {
    display: table;
    min-width: 100%;

    dt, dd {
      width: auto;
      overflow: visible;
    }

    dt {
      display: table-cell;
      text-align: left;
      white-space: nowrap;
      font-weight: 500;
      padding: 4px 0;
      width: 100px;
    }

    dd {
      display: table-cell;
      padding: 4px 0;

      &.line-break {
        display: table-row;
      }
    }
  }
}
