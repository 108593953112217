body .p-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #a6a6a6;

  &:not(.p-datepicker-inline) {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  &:not(.p-state-disabled) {
    .p-datepicker-header {
      .p-datepicker-prev:hover, .p-datepicker-next:hover {
        color: $brand-primary;
      }

      .p-datepicker-prev:focus, .p-datepicker-next:focus {
        outline: 0 none;
        color: $brand-primary;
      }
    }

    table td a:not(.p-highlight):not(.p-state-highlight):hover, .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
      background-color: #eaeaea;
    }
  }

  .p-datepicker-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    background-color: #ffffff;
    color: #333333;
    border-radius: 0;

    .p-datepicker-prev {
      cursor: pointer;
      top: 0;
      color: #a6a6a6;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
    }

    .p-datepicker-next {
      cursor: pointer;
      top: 0;
      color: #a6a6a6;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;

      span:before {
        content: "";
      }
    }

    .p-datepicker-prev span:before {
      content: "";
    }

    .p-datepicker-title {
      margin: 0;
      padding: 0;
      line-height: 1;

      select {
        margin-top: -.35em;
        margin-bottom: 0;
      }
    }
  }

  table {
    font-size: 14px;
    margin: 0.857em 0 0 0;

    th {
      padding: 0.5em;
    }

    td {
      padding: 0.5em;

      > {
        a, span {
          display: block;
          text-align: center;
          color: #333333;
          padding: 0.5em;
          border-radius: 3px;
        }

        a {
          cursor: pointer;

          &.p-highlight {
            color: #ffffff;
            background-color: $brand-primary;
          }

          &.p-state-highlight {
            color: #333333;
            background-color: #d0d0d0;
          }
        }
      }

      &.p-datepicker-today a {
        background-color: #d0d0d0;
        color: #333333;
      }
    }
  }

  .p-datepicker-buttonbar {
    border-top: 1px solid #d8dae2;
  }

  .p-timepicker {
    border: 0 none;
    border-top: 1px solid #d8dae2;
    padding: 0.857em;

    a {
      color: #333333;
      font-size: 1.286em;

      &:hover {
        color: $brand-primary;
      }
    }

    span {
      font-size: 1.286em;
    }
  }

  .p-monthpicker .p-monthpicker-month {
    color: #333333;

    &.p-highlight {
      color: #ffffff;
      background-color: $brand-primary;
    }
  }

  &.p-datepicker-timeonly {
    padding: 0;

    .p-timepicker {
      border-top: 0 none;
    }
  }

  &.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid #d8dae2;
    padding-right: 0.857em;
    padding-left: 0.857em;
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: 0 none;
    }
  }
}
