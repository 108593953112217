/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '~@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../assets/fonts/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(../assets/fonts/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(../assets/fonts/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$fontConfig: (
        display-4: mat.define-typography-level(112px, 112px, 300, 'Open Sans', -0.0134em),
        display-3: mat.define-typography-level(56px, 56px, 400, 'Open Sans', -0.0089em),
        display-2: mat.define-typography-level(45px, 48px, 400, 'Open Sans', 0.0000em),
        display-1: mat.define-typography-level(34px, 40px, 400, 'Open Sans', 0.0074em),
        headline: mat.define-typography-level(24px, 32px, 400, 'Open Sans', 0.0000em),
        title: mat.define-typography-level(20px, 32px, 500, 'Open Sans', 0.0075em),
        subheading-2: mat.define-typography-level(16px, 28px, 400, 'Open Sans', 0.0094em),
        subheading-1: mat.define-typography-level(15px, 24px, 500, 'Open Sans', 0.0067em),
        body-2: mat.define-typography-level(14px, 24px, 500, 'Open Sans', 0.0179em),
        body-1: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0.0179em),
        button: mat.define-typography-level(14px, 14px, 500, 'Open Sans', 0.0893em),
        caption: mat.define-typography-level(12px, 20px, 400, 'Open Sans', 0.0333em),
        input: mat.define-typography-level(inherit, 1.3, 400, 'Open Sans', 1px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #555555;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

$mat-light-theme-foreground-palette: (
        base:              black,
        divider:           rgba(black, 0.12),
        dividers:          rgba(black, 0.12),
        disabled:          rgba(black, 0.38),
        disabled-button:   rgba($dark-text, 0.26),
        disabled-text:     rgba(black, 0.38),
        elevation:         black,
        secondary-text:    $dark-accent-text,
        hint-text:         rgba(black, 0.38),
        accent-text:       $dark-accent-text,
        icon:              $dark-accent-text,
        icons:             $dark-accent-text,
        text:              rgba(black, 0.87),
        slider-min:        rgba(black, 0.87),
        slider-off:        rgba($dark-text, 0.26),
        slider-off-active: rgba(black, 0.38),
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$mat-dark-theme-foreground-palette: (
        base:              $light-text,
        divider:           rgba(white, 0.12),
        dividers:          rgba(white, 0.12),
        disabled:          rgba(white, 0.5),
        disabled-button:   rgba($light-text, 0.3),
        disabled-text:     rgba(white, 0.5),
        elevation:         black,
        hint-text:         rgba(white, 0.5),
        secondary-text:    $light-accent-text,
        accent-text:       $light-accent-text,
        icon:              $light-text,
        icons:             $light-text,
        text:              $light-text,
        slider-min:        $light-text,
        slider-off:        rgba($light-text, 0.3),
        slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #ffffff;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background-palette: (
        background:               $light-background,
        status-bar:               $light-bg-darker-20,
        app-bar:                  $light-bg-darker-5,
        hover:                    $dark-bg-alpha-4,
        card:                     $light-bg-lighter-5,
        dialog:                   $light-bg-lighter-5,
        tooltip:                  $dark-bg-tooltip,
        disabled-button:          $dark-bg-alpha-12,
        raised-button:            $light-bg-lighter-5,
        focused-button:           rgba(black, 0.12),
        selected-button:          $light-bg-darker-20,
        selected-disabled-button: $light-bg-darker-30,
        disabled-button-toggle:   $light-bg-darker-10,
        unselected-chip:          $light-bg-darker-10,
        disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#ffffff, 0.04);
$light-bg-alpha-12:   rgba(#ffffff, 0.12);

// Background palette for dark themes.
$dark-theme-background-palette: (
        background:               $dark-background,
        status-bar:               $dark-bg-lighter-20,
        app-bar:                  $dark-bg-lighter-5,
        hover:                    $light-bg-alpha-4,
        card:                     $dark-bg-lighter-5,
        dialog:                   $dark-bg-lighter-5,
        tooltip:                  $dark-bg-lighter-20,
        disabled-button:          $light-bg-alpha-12,
        raised-button:            $dark-bg-lighter-5,
        focused-button:           rgba(white, 0.12),
        selected-button:          $dark-bg-lighter-20,
        selected-disabled-button: $dark-bg-lighter-30,
        disabled-button-toggle:   $dark-bg-lighter-10,
        unselected-chip:          $dark-bg-lighter-20,
        disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #d93231;
  --primary-lighter-color: #f4c2c1;
  --primary-darker-color: #ca1f1f;
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
}

$mat-primary: (
        main: #d93231,
        lighter: #f4c2c1,
        darker: #ca1f1f,
        200: #d93231, // For slide toggle,
        contrast : (
                main: white,
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #797979;
  --accent-lighter-color: #d7d7d7;
  --accent-darker-color: #5c5c5c;
  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};
}

$mat-accent: (
        main: #797979,
        lighter: #d7d7d7,
        darker: #5c5c5c,
        200: #797979, // For slide toggle,
        contrast : (
                main: white,
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #d93231;
  --warn-lighter-color: #f4c2c1;
  --warn-darker-color: #ca1f1f;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}

$mat-warn: (
        main: #d93231,
        lighter: #f4c2c1,
        darker: #ca1f1f,
        200: #d93231, // For slide toggle,
        contrast : (
                main: white,
                lighter: rgba(black, 0.87),
                darker: white,
        )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);;

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Open Sans';
  }
}
