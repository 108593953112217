@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('#{$FontPathOpenSans}/Light/OpenSans-Light.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/Light/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('#{$FontPathOpenSans}/Semibold/OpenSans-Semibold.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/Semibold/OpenSans-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('#{$FontPathOpenSans}/Bold/OpenSans-Bold.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/Bold/OpenSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('#{$FontPathOpenSans}/LightItalic/OpenSans-LightItalic.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/LightItalic/OpenSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('#{$FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/SemiboldItalic/OpenSans-SemiboldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('#{$FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.woff2') format('woff2'),
  url('#{$FontPathOpenSans}/BoldItalic/OpenSans-BoldItalic.woff') format('woff');
}
