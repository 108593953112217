@use "sass:math";

@media print {

  .page-controls, .sidebar, .loader-wrap, .chat-sidebar {
    display: none;
  }

  .content-wrap {
    position: static;
  }

  .content {
    margin: 0;
    padding: 0;
  }


  .table, .table td, .table th {
    background: $white !important;
    border: none !important;
  }

  .table td {
    border-top: 1px solid $gray-light !important;
  }

  .hidden-print {
    display: none !important;
  }

  //special print grid system
  .col-print-1,
  .col-print-2,
  .col-print-3,
  .col-print-4,
  .col-print-5,
  .col-print-6,
  .col-print-7,
  .col-print-8,
  .col-print-9,
  .col-print-10,
  .col-print-11 {
    float: left;
  }
  .col-print-1 {
    width: percentage(math.div(1, $grid-columns));
  }
  .col-print-2 {
    width: percentage(math.div(2, $grid-columns));
  }
  .col-print-3 {
    width: percentage(math.div(3, $grid-columns));
  }
  .col-print-4 {
    width: percentage(math.div(4, $grid-columns));
  }
  .col-print-5 {
    width: percentage(math.div(5, $grid-columns));
  }
  .col-print-6 {
    width: percentage(math.div(6, $grid-columns));
  }
  .col-print-7 {
    width: percentage(math.div(7, $grid-columns));
  }
  .col-print-8 {
    width: percentage(math.div(8, $grid-columns));
  }
  .col-print-9 {
    width: percentage(math.div(9, $grid-columns));
  }
  .col-print-10 {
    width: percentage(math.div(10, $grid-columns));
  }
  .col-print-11 {
    width: percentage(math.div(11, $grid-columns));
  }
  .col-print-12 {
    width: 100%;
  }

  // Push and pull columns for source order changes
  .col-print-push-0 {
    left: auto;
  }
  .col-print-push-1 {
    left: percentage(math.div(1, $grid-columns));
  }
  .col-print-push-2 {
    left: percentage(math.div(2, $grid-columns));
  }
  .col-print-push-3 {
    left: percentage(math.div(3, $grid-columns));
  }
  .col-print-push-4 {
    left: percentage(math.div(4, $grid-columns));
  }
  .col-print-push-5 {
    left: percentage(math.div(5, $grid-columns));
  }
  .col-print-push-6 {
    left: percentage(math.div(6, $grid-columns));
  }
  .col-print-push-7 {
    left: percentage(math.div(7, $grid-columns));
  }
  .col-print-push-8 {
    left: percentage(math.div(8, $grid-columns));
  }
  .col-print-push-9 {
    left: percentage(math.div(9, $grid-columns));
  }
  .col-print-push-10 {
    left: percentage(math.div(10, $grid-columns));
  }
  .col-print-push-11 {
    left: percentage(math.div(11, $grid-columns));
  }

  .col-print-pull-0 {
    right: auto;
  }
  .col-print-pull-1 {
    right: percentage(math.div(1, $grid-columns));
  }
  .col-print-pull-2 {
    right: percentage(math.div(2, $grid-columns));
  }
  .col-print-pull-3 {
    right: percentage(math.div(3, $grid-columns));
  }
  .col-print-pull-4 {
    right: percentage(math.div(4, $grid-columns));
  }
  .col-print-pull-5 {
    right: percentage(math.div(5, $grid-columns));
  }
  .col-print-pull-6 {
    right: percentage(math.div(6, $grid-columns));
  }
  .col-print-pull-7 {
    right: percentage(math.div(7, $grid-columns));
  }
  .col-print-pull-8 {
    right: percentage(math.div(8, $grid-columns));
  }
  .col-print-pull-9 {
    right: percentage(math.div(9, $grid-columns));
  }
  .col-print-pull-10 {
    right: percentage(math.div(10, $grid-columns));
  }
  .col-print-pull-11 {
    right: percentage(math.div(11, $grid-columns));
  }

  // Offsets
  .col-print-offset-0 {
    margin-left: 0;
  }
  .col-print-offset-1 {
    margin-left: percentage(math.div(1, $grid-columns));
  }
  .col-print-offset-2 {
    margin-left: percentage(math.div(2, $grid-columns));
  }
  .col-print-offset-3 {
    margin-left: percentage(math.div(3, $grid-columns));
  }
  .col-print-offset-4 {
    margin-left: percentage(math.div(4, $grid-columns));
  }
  .col-print-offset-5 {
    margin-left: percentage(math.div(5, $grid-columns));
  }
  .col-print-offset-6 {
    margin-left: percentage(math.div(6, $grid-columns));
  }
  .col-print-offset-7 {
    margin-left: percentage(math.div(7, $grid-columns));
  }
  .col-print-offset-8 {
    margin-left: percentage(math.div(8, $grid-columns));
  }
  .col-print-offset-9 {
    margin-left: percentage(math.div(9, $grid-columns));
  }
  .col-print-offset-10 {
    margin-left: percentage(math.div(10, $grid-columns));
  }
  .col-print-offset-11 {
    margin-left: percentage(math.div(11, $grid-columns));
  }

}
