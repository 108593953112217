.tx-barcode-scanner {
  position: relative;

  &__container {
    position: relative;
    //height: 500px;
    overflow: hidden;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--visible {
      display: flex;
    }

    video {
      //max-width: 300px;
      max-height: 500px;
    }
  }

  &__select {
    margin-bottom: 10px;
  }

  &__helper {
    position: absolute;
    height: 2px;
    background-color: $brand-primary;
    width: 90%;
  }
}
