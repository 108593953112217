.calendar-data {
  th {
    text-align: center;
  }
}

.display-cell-inline-block table#headerpersons td {
  display: inline-block!important;
}

.flatcalendar {

  .ng-pagination {
    text-align: right;
  }

  .ng-items-per-page {
    text-align: right;

    a.active {
      font-weight: bold;
    }
  }

  .ngx-pagination {
    margin-bottom: 0;

    li {
      padding: 2.8px;
      text-align: center;
      border-radius: .25em;
      margin-left: 2px;
      height: 100%;

      &.current {
        background: $brand-primary;
        border-radius: .25em;

        &:hover {
          background: $brand-primary;
        }
      }

      &:hover {
        background: #eee;
      }
    }

    a, span {
      border-radius: .25em;
      margin: 3.8px;
      display: block;
      width: 20px;
      padding: 0;

      &:hover {
        background: transparent;
      }
    }

    div {
      border-radius: .25em;
      margin: 3.8px;
      display: block;
      width: 20px;
    }
  }


  .scheduletable {

    td.today {
      background: #ffeeee !important;
    }

    th.today:not(.years):not(.month) {
      background: #ffeeee !important;
    }

    width: 100%;
    position: relative;

    thead {
      border-bottom: 2px solid #555;

      tr {
        height: 50px;

        &:last-child {
          height: 80px;

          th {
            border: 1px solid #eee;
          }
        }
      }
    }

    tbody {
      tr {
        height: 30px;

        td {
          border: 1px solid #eee;
        }
      }
    }
  }

  .pinned {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;

    .table-overflow {
      overflow: hidden;

      tr.with-info-field {
        td {
          @include media-breakpoint-down(md) {
            width: 50%;
          }
        }
      }

      td {
        @include media-breakpoint-down(md) {
          font-size: 13px;
          padding: 0;
          width: 100%;
          display: inline-block;
          line-height: 13px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 150px;
    }

    table {
      width: 100%;

      tr {
        &.month {
          th {
            background: #eee;
          }
        }
      }

      th, td {
        padding: 0 5px;
      }

      th {
        width: 50%;
      }

      td {
        display: table-cell;
        width: 50%;
        height: 38px;
        max-height: 38px;
        max-width: 175px;
        overflow: hidden;
      }
    }
  }

  .scrollable {
    margin-left: 350px;
    overflow: hidden;


    .table-overflow {
      overflow: auto;
    }

    @include media-breakpoint-down(md) {
      margin-left: 150px;
    }

    tr {
      td {
        min-width: 28px;
        max-width: 28px;
        height: 38px;
        text-align: center;
      }
    }

    .calendar-data {

      tr {
        &.years {
          th {
            text-align: center;
            font-weight: 100;
            text-transform: uppercase;
            padding: 10px 0;
            border-left: 1px solid #eee;

            &:first-child {
              border-left: 1px solid transparent;
            }
          }
        }

        &.month {
          th {
            text-align: center;
            font-weight: 100;
            text-transform: uppercase;
            padding: 10px 0;
            border-left: 1px solid #fff;
            background: #eee;
          }
        }

        &.days {
          th {
            text-align: center;
            font-size: 14px;
            font-weight: 100;
            padding: 10px 5px 5px 5px;
            line-height: 30px;
            border-left: 1px solid #eee;
            min-width: 28px;
            max-width: 28px;

            span {
              display: block;
              font-size: 16px;
              font-weight: 900;

            }
          }
        }
      }

      tr {
        td {
          min-width: 28px;
          max-width: 28px;
          text-align: center;
        }
      }

    }
  }
}


/*
  QND fixes by CW: entries per page and pagination should look like
 */
.flatcalendar .ngx-pagination li.current {
  background-color: #eeeeee !important;
  color: #555555 !important;
}


.flatcalendar .ngx-pagination li.current:hover {
  background: #eeeeee !important;
}
:root {
  --fc-event-text-color: $gray;
}
