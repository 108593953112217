body {
	.p-panelmenu {
		.p-panelmenu-header,
		.p-panelmenu-header:not(.p-highlight) {
			> a {
				border: 0;
				background-color: transparent;
				color: white;
				&:hover {
					border: 0;
					background-color: #111;
					color: white;
				}
				&:focus {
					box-shadow: none;
				}

				.p-menuitem-icon {
					border-radius: 50%;
					//font-size: 2em;
					padding: 0.2rem;
				}
			}
		}
		.p-panelmenu-header.p-highlight {
			background-color: #111;
			> a {
				outline: 0;
				border: 0;
				background-color: #111;

				color: #d93231;

				&:hover {
					border: 0;
					background-color: transparent;
				}

				.p-menuitem-icon {
					color: #242424;
					background-color: #d93231;
				}
			}
		}

		.p-panelmenu-content {
			background-color: transparent;
			border: 0;
			color: white;

            border-bottom: 1px solid #2d2d2d;

			.p-menuitem {
				.p-menuitem-link {
                    .p-menuitem-icon,
                    .p-panelmenu-icon,
                    .p-menuitem-text {
                      color: white;
                    }
                  .p-panelmenu-icon,
                  .p-menuitem-icon {
                    font-size: 1.25rem;
                  }
                  &:hover {
                    background-color: #111;
                    .p-menuitem-icon,
                    .p-panelmenu-icon,
                    .p-menuitem-text {
                      color: #333;
                    }
                  }
                  &.p-highlight {
                    background-color: transparent;
                  }
                  &.p-menuitem-link-active {
                    > * {
                      color: #d93231 !important;
                    }
                  }
				}
			}
		}
	}
  .p-accordion {
    .p-accordion-header:not(.p-state-disabled).p-highlight, .p-accordion-header:not(.p-state-disabled) {
      .p-accordion-header-link {
        background-color: white;
        color: #111;
        border: 0;
        text-decoration: none !important;
      }

      &:hover, &:active {
        .p-accordion-header-link {
          background-color: darken(rgb(255, 255, 255), 2%);
          color: black;

          a {
            text-decoration: none !important;
          }
        }
      }
    }
    .p-accordion-content {
      border: 0;
    }
  }
}


