/***********************************/
/**             LOGIN             **/
/***********************************/

.login-page {
  background-color: $gray-semi-lighter;
}

.login-page .page-footer {
  margin-bottom: 25px;
  font-size: $font-size-mini;
  color: $text-muted;
  text-align: center;
  @media (min-height: 600px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.widget-login-container {
  padding-top: 10%;
}

.widget-login-logo {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: $font-weight-normal;

  .fa-circle {
    font-size: 13px;
    margin: 0 20px;
  }

  &.pin-mode-logo {
    position: relative;
    text-align: left;
  }
}

.widget-login {
  padding: 30px;

  > header {
    h1, h2, h3, h4, h5, h6 {
      font-weight: $font-weight-normal;
      text-align: center;
    }
  }
}

.widget-login-info {
  font-size: $font-size-mini;
  color: #888;
  margin-top: 1px;
  margin-bottom: 0;
  text-align: center;

  &.abc-checkbox {
    margin-left: -25px;
  }
}

.login-form {
  .form-control {
    font-size: $font-size-mini;
    border: none;
    background-color: $gray-lighter;

    &:focus {
      background-color: $gray-semi-lighter;
    }
  }
}


/*------
# Pincode
----*/

.pin-code {
  position: fixed;
  overflow: hidden;
  height: 500px;
  width: 375px;
  z-index: 9999;
  background-size: cover;
  background-position: center;
  text-align: center;

  .numbers {
    max-width: 300px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    display: block;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    transition: all 1s ease-out;
    opacity: 1;

    &.hide {
      opacity: 0.3;
    }
  }

  button {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 50px;
    border-radius: 100%;
    opacity: 1;
    outline: 0;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

    &:active {
      background-color: $brand-primary;
      color: #fff;
      outline: 0;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
    }
  }

  .fields {
    max-width: 200px;
    padding: 0 20px;
    margin: 20px auto 30px;
    position: relative;
    display: block;

    .numberfield {
      text-align: center;

      span {
        height: 10px;
        width: 10px;
        border: 2px solid #343434;
        background-color: transparent;
        border-radius: 100%;
        position: relative;
        display: inline-block;
        text-align: center;
      }

      &.active span {
        background-color: $brand-primary;
        border-color: $brand-primary;
      }

      &.right span {
        background-color: #272a2f;
        border-color: #272a2f;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
      }
    }
  }
}


/*------
# Toast Grid
----*/

.pin-code {


  /**
  Grid
   */
  .grid {
    list-style: none;
    margin-left: -20px;
  }

  .grid__col--1-of-3, .grid__col--2-of-6, .grid__col--4-of-12 {
    width: 33.33333%;
  }

  .grid__col--1-of-4, .grid__col--2-of-8, .grid__col--3-of-12 {
    width: 25%;
  }

  .grid__col {
    box-sizing: border-box;
    display: inline-block;
    margin-right: -.25em;
    min-height: 1px;
    padding-left: 20px;
    vertical-align: top;
  }

  .grid__col--centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .grid__col--d-first {
    float: left;
  }

  .grid__col--d-last {
    float: right;
  }

  .grid--no-gutter {
    margin-left: 0;
    width: 100%;

    .grid__col {
      padding-left: 0;
    }

    .grid__col--span-all {
      margin-left: 0;
      width: 100%;
    }
  }

  .grid__col--ab {
    vertical-align: bottom;
  }

  .grid__col--am {
    vertical-align: middle;
  }
}

.miss {
  -webkit-animation: miss .8s ease-out 1;
  animation: miss .8s ease-out 1;
}

@-webkit-keyframes miss {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes miss {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  10% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  20% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  30% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
  }
  40% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0);
  }
  60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
  }
  70% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
  }
  80% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

button.pin-login-switch {
  position: absolute;
  top: -5px;
  right: 1rem;
  border: 0;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  background-color: $brand-primary;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 100%;
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: center;
  align-items: center;

  .pin-login-switch__pin {
    width: 25px;
  }

  .pin-login-switch__input {
    width: 40px;
  }
}
