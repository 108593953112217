.table-cell {
  display: table-cell!important;
}

@media screen and (max-width: 960px) {
  #balancesTable .p-datatable-thead > tr > th.show, #balancesTable .p-datatable-tfoot > tr > td.show {
    display: table-cell!important;
  }
}


body {
  .p-datatable {
    .p-datatable-caption,
    .p-datatable-summary {
      background-color: #fff;
    }

    .p-datatable-thead {
      > tr {
        > th {
          border: 0;
        }
      }
    }

    .p-datatable-tbody {
      > tr,
      > tr:nth-child(even) {
        //background-color: #fff;
        border-bottom: 1px solid #ddd;

        &.weekend {
          background-color: #eee;
        }

        &.p-state-highlight {
          background-color: rgba(100, 189, 99, 0.2);
          color: #555;
        }

        &:hover {
          background-color: rgba($brand-primary, 0.1);
          color: #333;
        }

        > td {
          white-space: nowrap;
          border: 0;
          &.wrap {
            white-space: normal;
          }
        }
      }
    }
  }


  .table.p-table .p-table-thead > tr > th, .table.p-table .p-table-tbody > tr > td, .table.p-table .p-table-tfoot > tr > td {
    padding: 1rem;
  }

  .table.p-table .p-widget-header {
    border: 0;
  }

  .table.p-table tr.p-highlight, .table.p-table tr.p-state-highlight {
    background: rgba($brand-success, 0.2);
    color: #555;
  }

  .table.p-table th.p-highlight, .table.p-table th.p-state-highlight {
    background: transparent;
    color: #555;
  }

  .table.p-table .p-chkbox .p-chkbox-box.p-highlight {
    border: 1px solid $brand-success;
    background-color: $brand-success;
    color: #ffffff;
  }

  .table.p-table .p-paginator {
    .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last, .p-paginator-pages .p-paginator-page {
      color: $brand-primary;
    }

    .p-paginator-pages .p-paginator-page.p-highlight {
      color: #fff;
    }

  }

  .table.p-table table {
    table-layout: auto;
  }

  .table.p-table .p-table-wrapper {
    overflow: auto;
  }
  tr.bb-0 {
    border-bottom: 0 none !important;
    td[rowspan] {
      border-bottom: 1px solid #ddd;
    }
  }
  tr.bb-0.hover,
  tr.bb-0.hover + tr,
  tr.bb-0:hover + tr {
    background-color: rgba($brand-primary, 0.05) !important;
  }
  tr.bb-0 td {
    padding-bottom: 0 !important;
  }
  tr.bb-0 + tr td {
    padding-top: 0 !important;
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  // from 0-960px and from 1200px-1500px
  @media screen and (max-width: 960px) {
    tx-teamleiter .p-datatable.p-datatable-responsive {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-table-tbody > tr > td, .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: .4rem;
          min-width: 30%;
          display: inline-block !important;
          margin: -.4em 1em -.4em -.4rem;
          font-weight: bold;
        }
        // column has no content, hide it
        //&.c0 {
        //  display:none !important;
        //}
        &.wrap span:last-child {
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 50vw;
          overflow: hidden;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
  @media screen and (max-width: 960px), screen and (min-width: 1200px) and (max-width: 1500px) {
    tx-txworkflow .p-datatable.p-datatable-responsive {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-table-tbody > tr > td, .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: .4rem;
          min-width: 30%;
          display: inline-block !important;
          margin: -.4em 1em -.4em -.4rem;
          font-weight: bold;
        }
        // column has no content, hide it
        //&.c0 {
        //  display:none !important;
        //}
        &.wrap span:last-child {
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 50vw;
          overflow: hidden;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
