.mat-form-field-flex {
  position: relative;
  .mat-form-field-suffix {
    /*position: absolute;
    bottom: 0.4375em;
    right: 0;*/
  }
}

.mat-list-option {
  &:hover {
    background-color: rgba(217, 50, 49, 0.05);
  }
}

.cdk-overlay-pane {
  transform: translateX(-16px) translateY(+30px) !important;
}

.mat-card-header-text {
  width: 100%;
}

.mat-header-cell {
  background-color: darken($white, 3%);
  color: $black;
  font-weight: bold;
}
