body .p-dropdown {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  transition: border-color 0.2s;

  &:not(.p-state-disabled) {
    &:hover {
      border-color: lighten($brand-primary, 15%);

      .p-dropdown-label,
      .p-dropdown-trigger {
        background-color: #fff;
      }
    }

    &:focus {
      border-color: $brand-primary;
      outline: 0 none;
      box-shadow: none;
    }
  }

  .p-dropdown-label {
    padding-right: 2em;
  }

  .p-dropdown-trigger {
    background-color: #ffffff;
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    color: #848484;

    .p-dropdown-trigger-icon:before {
      content: "";
    }
  }

  .p-dropdown-clear-icon {
    color: #848484;
  }

  &:not(.p-state-disabled).p-state-focus {
    border-color: $brand-primary;
    outline: 0 none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

body .p-dropdown-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .p-dropdown-filter-container {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: #333333;
    background-color: #ffffff;
    margin: 0;

    .p-dropdown-filter {
      width: 100%;
      padding-right: 2em;
    }

    .p-dropdown-filter-icon {
      top: 50%;
      margin-top: -.5em;
      right: 1.357em;
      color: $brand-primary;
    }
  }

  .p-dropdown-items {
    padding: 0;

    .p-dropdown-item, .p-dropdown-item-group {
      margin: 0;
      padding: 0.429em 0.857em;
      border: 0 none;
      color: #333333;
      background-color: transparent;
      border-radius: 0;
    }

    .p-dropdown-item.p-state-highlight, .p-dropdown-item-group.p-state-highlight {
      color: #ffffff;
      background-color: $brand-primary;
    }

    .p-dropdown-item:not(.p-state-highlight):not(.p-state-disabled):hover, .p-dropdown-item-group:not(.p-state-highlight):not(.p-state-disabled):hover {
      color: #333333;
      background-color: #eaeaea;
    }
  }
}

body .p-multiselect {
  background: #ffffff;
  border: 1px solid #a6a6a6;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;

  &:not(.p-state-disabled) {
    &:hover {
      border-color: #212121;
    }

    &:focus {
      border-color: $brand-primary;
      outline: 0 none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .p-multiselect-label {
    padding: 0.429em;
    padding-right: 2em;
    color: #333333;
  }

  .p-multiselect-trigger {
    background-color: #ffffff;
    width: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0;
    color: #848484;

    .p-multiselect-trigger-icon:before {
      content: "";
    }
  }
}

body .p-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .p-multiselect-header {
    padding: 0.429em 0.857em 0.429em 0.857em;
    border-bottom: 1px solid #eaeaea;
    color: #333333;
    background-color: #ffffff;
    margin: 0;

    .p-multiselect-filter-container {
      float: none;
      width: 70%;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0;

      .p-inputtext {
        padding: 0.429em;
        padding-right: 2em;
      }

      .p-multiselect-filter-icon {
        color: $brand-primary;
        top: 50%;
        margin-top: -.5em;
        right: .5em;
        left: auto;
      }
    }

    .p-chkbox {
      margin-right: 0.5em;
      float: none;
      vertical-align: middle;
    }

    .p-multiselect-close {
      color: #848484;
      top: 50%;
      margin-top: -.5em;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;

      &:hover {
        color: #333333;
      }

      &:focus {
        outline: 0 none;
        color: #333333;
      }
    }
  }

  .p-multiselect-items {
    padding: 0;

    .p-multiselect-item {
      margin: 0;
      padding: 0.429em 0.857em;
      border: 0 none;
      color: #333333;
      background-color: transparent;
      border-radius: 0;

      &.p-state-highlight {
        color: #ffffff;
        background-color: $brand-primary;
      }

      &:not(.p-state-highlight):not(.p-state-disabled):hover {
        color: #333333;
        background-color: #eaeaea;
      }

      &.p-state-disabled .p-chkbox-box {
        cursor: auto;

        &:hover {
          border: 1px solid #a6a6a6;
        }

        &.p-highlight:hover {
          border-color: $brand-primary;
        }
      }

      .p-chkbox {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.5em 0 0;
      }

      label {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
