@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "@fortawesome/fontawesome-free/css/all.css";

@import "style/wl-material-theme.scss";

/* You can add global styles to this file, and also import other style files */
@import "style/app";

//Material UI Theme
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/*

html, body {
  height: 100%;
}
*/
