.clockpicker-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  &.top {
    margin-top: -10px;
  }

  &.right {
    margin-left: 10px;
  }

  &.bottom {
    margin-top: 10px;
  }

  &.left {
    margin-left: -10px;
  }
}

clockpicker-popover {
  .popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
  }

  .popover-content {
    padding: 9px 14px;
  }
}

.clockpicker-popover {
  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    /* The following are set in WordPress (wp-admin/css/revisions.css) - reset them to initial values */
    overflow: visible;
    margin: 0;
    padding: 0;
    z-index: auto;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;

    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      /* The following are set in WordPress (wp-admin/css/revisions.css) - reset them to initial values */
      overflow: visible;
      margin: 0;
      padding: 0;
      z-index: auto;
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      content: "";
      border-width: 10px;
    }

    border-width: 11px;
  }

  &.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;

    &:after {
      bottom: 1px;
      margin-left: -10px;
      content: " ";
      border-top-color: #fff;
      border-bottom-width: 0;
    }
  }

  &.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;

    &:after {
      bottom: -10px;
      left: 1px;
      content: " ";
      border-right-color: #fff;
      border-left-width: 0;
    }
  }

  &.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);

    &:after {
      top: 1px;
      margin-left: -10px;
      content: " ";
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);

    &:after {
      right: 1px;
      bottom: -10px;
      content: " ";
      border-right-width: 0;
      border-left-color: #fff;
    }
  }

  .btn {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;

    &:focus, &:active:focus, &.active:focus {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:hover, &:focus {
      color: #333;
      text-decoration: none;
    }

    &:active, &.active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }

  .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &:hover, &:focus, &:active, &.active {
      color: #333;
      background-color: #ebebeb;
      border-color: #adadad;
    }
  }

  .open .dropdown-toggle.btn-default {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
  }

  .btn-default {
    &:active, &.active {
      background-image: none;
    }
  }

  .open .dropdown-toggle.btn-default {
    background-image: none;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  .text-primary {
    color: $brand-primary;
  }

  .form-control {
    &:disabled, &[readonly] {
      background-color: #fff;
    }
  }

  .input-group-addon {
    cursor: pointer;
  }

  &.clockpicker-moving {
    cursor: move;
  }

  &.clockpicker-align-left.popover > .arrow {
    left: 25px;
  }

  &.clockpicker-align-top.popover > .arrow {
    top: 17px;
  }

  &.clockpicker-align-right.popover > .arrow {
    left: auto;
    right: 25px;
  }

  &.clockpicker-align-bottom.popover > .arrow {
    top: auto;
    bottom: 6px;
  }

  .popover-title {
    background-color: #fff;
    color: #999;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;

    span {
      cursor: pointer;
    }
  }

  .popover-content {
    background-color: #f8f8f8;
    padding: 12px;

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .clockpicker-plate {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    overflow: visible;
    position: relative;
    user-select: none;
  }

  .clockpicker-canvas, .clockpicker-dial {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -1px;
    top: -1px;
  }

  .clockpicker-minutes {
    visibility: hidden;
  }

  .clockpicker-tick {
    border-radius: 50%;
    color: #666;
    line-height: 26px;
    text-align: center;
    width: 26px;
    height: 26px;
    position: absolute;
    cursor: pointer;

    &.active, &:hover {
      background-color: rgb(192, 229, 247);
      background-color: rgba($brand-primary, 0.25);
    }
  }

  .clockpicker-button {
    background-image: none;
    background-color: #fff;
    border-width: 1px 0 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    padding: 10px 0;

    &:hover {
      background-image: none;
      background-color: #ebebeb;
    }

    &:focus {
      outline: none !important;
    }
  }

  .clockpicker-dial {
    transition: transform 350ms, opacity 350ms;
  }

  .clockpicker-dial-out {
    opacity: 0;
  }

  .clockpicker-hours.clockpicker-dial-out {
    transform: scale(1.2, 1.2);
  }

  .clockpicker-minutes.clockpicker-dial-out {
    transform: scale(0.8, 0.8);
  }

  .clockpicker-canvas {
    transition: opacity 175ms;
  }

  .clockpicker-canvas-out {
    opacity: 0.25;
  }

  .clockpicker-canvas-bearing, .clockpicker-canvas-fg, .clockpicker-canvas-bg {
    stroke: none;
    fill: $brand-primary;
  }

  .clockpicker-canvas-bg-trans {
    fill: rgba($brand-primary, 0.25);
  }

  .clockpicker-canvas line {
    stroke: $brand-primary;
    stroke-width: 1;
    stroke-linecap: round;
  }

  .clockpicker-button {
    &.am-button {
      margin: 1px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    &.pm-button {
      margin: 1px 1px 1px 136px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }
}
