body .p-inputtext {
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  transition: border-color 0.2s;
  appearance: none;

  &:enabled {
    &:hover:not(.p-state-error) {
      border-color: lighten($brand-primary, 15%);
    }

    &:focus:not(.p-state-error) {
      border-color: $brand-primary;
      outline: 0 none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
